import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function IfIEverLoseMyFaithInYou() {
  return (
    <Layout>
      <Helmet title="If I Ever Lose My Faith In You | Sting" />

      <h1>If I Ever Lose My Faith In You</h1>

      <h2>Sting</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>You could say I lost my faith in science and progress</p>
          <p>You could say I lost my belief in the holy Church</p>
          <p>You could say I lost my sense of direction</p>
          <p>You could say all of this and worse, but</p>
        </Verse>
        <Chorus>
          <p>If I ever lose my faith in you</p>
          <p>There'd be nothing left for me to do</p>
        </Chorus>
        <Verse>
          <p>Some would say I was a lost man in a lost world</p>
          <p>You could say I lost my faith in the people on TV</p>
          <p>You could say I'd lost my belief in our politicians</p>
          <p>They all seemed like game show hosts to me</p>
        </Verse>
        <Chorus>
          <p>If I ever lose my faith in you</p>
          <p>There'd be nothing left for me to do</p>
        </Chorus>
        <Bridge>
          <p>I could be lost inside their lies without a trace</p>
          <p>But every time I close my eyes I see your face</p>
        </Bridge>
        <Verse>
          <p>I never saw no miracle of science</p>
          <p>That didn't go from a blessing to a curse</p>
          <p>I never saw no military solution</p>
          <p>That didn't always end up as something worse, but</p>
          <p>Let me say this first</p>
        </Verse>
        <Chorus>
          <p>If I ever lose my faith in you</p>
          <p>There'd be nothing left for me to do</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
